:root{
	font-size: 16px;
	color: white;
}
html{
	scroll-behavior: smooth;
}
*{
	padding:0;
	margin:0;
	/* color:white; */
	box-sizing: border-box;


}
blockquote p{
    font-size: 19px;
    font-style: oblique;
    font-family: "Arima Madurai",cursive;
    font-weight: 350;
    letter-spacing: 0.3px;
}
.desktop-view{
	display: block;
	height:100vh;
	overflow:hidden;
}
.mobile-view{
	display:none;
}
.modals{
	position: fixed;
	z-index:999;
	left:0;
	right:0;
	top:0;
	bottom:0;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity:0;
	transition: all 0.3s ease-in-out;
	pointer-events: none;
	color:white;
	
}
.modals.show{
	opacity:1;
	pointer-events:visible;
}
.form-control{
	padding: 0.475rem 0.95rem;
    font-size: 1rem;
}
.modals-content{
	width:500px;
	background-color: #6a7c68;
	padding:5px 20px;
	border-radius: 5%;
	
	transform: translateY(-200px);
	transition:all 0.3s ease-in-out;
}
.modals-body{
	padding:10px;


}

.modals-header,.modals-footer{
	padding:10px;
}
.modals-footer {
	display: flex;
	justify-content: flex-end;
}
.modals-footer button{
	margin-left:10px;
}
.text,.mobile-text{
	color:#fff;
}

.modals.show .modals-content{
	transform: translateY(0);
}
button.link{
	border:none;
	color:white;
	border-radius:0;
}

/* .modal-dialog{
	z-index:999;
} */



@keyframes slide {
	from {
		left: 0px;
	}
	to {
		right: 100px;
	}
}



@media(min-width: 1200px) {
	
	.textTitle {
		width:75%;
		top:50%;
	}

}
@media(max-width: 850px) {
	.desktop-view{
		display:none;
	}
	.mobile-view{
		display:block;
	}
}
.modals-content{
	border-radius: 0;
}
.form-control{
	background-color: #7D8E7B !important;
    border: 1px solid #7D8E7B !important;
	border-radius: 0 !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #f1f1f1 !important;
	opacity: 1 !important; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #f1f1f1 !important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color: #f1f1f1 !important;
  }