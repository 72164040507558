.imageSection {
	flex: 58.3%;
	opacity:1;
	background-size: cover;
	background-position: center;

	
	 animation: zoom-in-zoom-out 25s ease-in infinite;
    height: 100vh;
}
@keyframes zoom-in-zoom-out {
	0% {
	transform: scale(1, 1);
	}
	50% {
	transform: scale(1.2, 1.2);
	} 
	100% {
	transform: scale(1, 1);
	} 
}	
.flex{
	display:flex;
	flex-direction: row;
}
.imageSection img {
   
	width: 100%;
	height: 100vh;
}
.nav-indicator {
	position: relative;
	width: 75px;
	left: 50%;
	transform: translateX(-50%);
	animation: fadeIn 4s;
	animation-duration: 2s;
}
.textSection {
	flex: 41.7%;
	padding: 0px 20px;
	background-color: #6a7c68;
	position: relative;
}
.textSection a:hover {
	color: #d1ccc1;
}
.bottomNav div{
	width:33.33%;
}
.textSection .bottom {
	position: absolute;
	bottom: 0;
	width: 30%;
	right: 3%;
}
.textNav {
	margin-top: 20px;
	flex: 50%;
	display: flex;
	justify-content: space-around;
}


.textNav a {
	text-decoration: none;
	color: rgb(255, 255, 255);
	word-spacing: 1px;
	font-size: 1.4rem;
	font-family: 'Arima Madurai', cursive;
	font-weight: bold;
	line-height: 2.5;
	letter-spacing: 1px;
}
.title {
	font-family: 'Arima Madurai', cursive;
	font-size: 1.3rem;
	margin-top:5px; 
	margin-bottom: 12px;
	color: rgb(255, 255, 255);
	font-weight: bold;
	line-height: 1.2;
	word-spacing: 5px;
}
.text {
	font-family: 'Roboto', sans-serif;
	font-size: .9rem;
}
.textTitle {
	position: absolute;
	width: 75%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.bottomNav {
	position: absolute;
	bottom: 5%;
    width:90%;
	
	margin:0 auto;
}
.abc{
	width:33.33%;
}
.bottomNav a{
	position:relative;
}
.bottomNav img{
	animation: fadeIn 4s;
}
/* .bottomNav #marker{
	position:absolute;
	left:0;
	height:4px;
	width:0%;
	
	background:#000;
	transition: 0.5s;
	border-radius:4px;
} */
.smallText {
	text-align: left;
	flex: 1;
	text-decoration: none;
	color: white;
	font-size:.9rem;
	
}
.link-wrap{
	margin-top:4%;
}
.link{
	padding:5px 10px;
	background-color: rgb(0, 0, 0,.4);
}
.contain{

	max-height: 40vh;
	overflow-y:scroll;
	scrollbar-color: #6a7c68;
	scrollbar-gutter: stable;	
}
.contain::-webkit-scrollbar {
    width: 6px;
}
 
.contain::-webkit-scrollbar-track {
    /* background-color: #e4e4e4; */
    border-radius: 100px;
}
 
.contain::-webkit-scrollbar-thumb {
    background-color: #3d473b;
    border-radius: 100px;
}
.borderTop{
	margin: 0 10px;
	border-top: 3px solid rgba(87, 85, 85, 0.336);
	

}
.bottomNav .active{
	border-top: 3px solid rgb(255, 255, 255);
	animation: fadeIn 4s;
}

.banner-text{
	position:absolute;
	top:25%;

	width:30%;
	margin-left:10%;
	/* transform: translate(-50%,-25%); */

	color:white;
	font-family: 'Arima Madurai', cursive;
	font-size:2rem;
	letter-spacing: .05rem;;
	word-spacing: 10px;;
	/* padding:0 80px; */
}
@keyframes fadeIn {
    from {opacity: 0.3;}
    to {opacity: 1;}
  }
  @media(max-width: 900px) {
	
  }
  @media(max-width: 1050px) {
	.imageSection {
		flex: 50%;
	}
	.banner-text{
		width:30%;
		font-size: 2rem;
	}
	.textSection {
		flex: 50%;
		padding: 10px;
	
	}
	.textTitle {
	position: absolute;
	width: 75%;
	top: 50%;
	left: 50%;
	/* transform: translate(-50%, -50%); */
	}

}

@media(max-width: 1100px) {
	.textSection{
		flex:60%;
	}
	.imageSection{
		flex:40%;
	}
}
/* @media (min-width:1024px) and(min-height:1366px){
	.title{
		font: 20px;;
	}
} */
@media(min-width:1400px){
	.textNav {
		margin-top: 40px;
		flex: 50%;
		display: flex;
		justify-content: space-around;
	}
	.title{
		font-size: 1.6rem;
	}

    .text{
		font-size:1.03rem;
	}
	.bottomNav {
		position: absolute;
		bottom: 8%;
		/* width:90%; */
		
	}
}
@media(min-width:2000px){
	.textNav {
		margin-top: 40px;
		flex: 50%;
		display: flex;
		justify-content: space-around;
	}
	.title{
		font-size: 1.9rem;
	}
	.textNav a{
		font-size:2.2rem;
	}
    .text{
		font-size:1.7rem;
	}
	.bottomNav {
		position: absolute;
		bottom: 8%;
		/* width:90%; */
		
	}
	.smallText{
		font-size:1.5rem;
	}
}
.brand-list{
	position: relative;
}
.brand-list .selector{
	position: absolute;
	top: -25px;
}
.brandlist-wrapper{
	margin-top: 25px
}