.textSection-mobile{
	background-color: #6a7c68;

    background-size: cover;
    height:100%;
}
.logo-small img {
	width: 70px;
}
.menu-bars{
    color:white;
	font-size:2rem;
}

.nav-menu{
	display: flex;
	width:200px;
	background-color: #6a7c68;
	position: fixed;
	top:0;
	right:-100%;
	height:100vh;
    z-index:100;
    transition:850ms;
}
/* .fixed{
overflow: hidden;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
} */
.nav-menu.active{
    right:0;
    transition: 350ms;;
}
.nav-text{
    display: flex;
    align-items:center;
    padding: 8px 0 8px 16px;
    height:60px;
    list-style: none;
}
.nav-text a{
    text-decoration:none;
	color:white;
    font-size: 1.2rem;
    border-bottom:0px solid #f1f1f1;
}
.nav-text .active{
    border-bottom:2px solid #f1f1f1;

}
.bgSection{
    position:relative;
    top:0;
    left:0;
    min-height:80vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index:1;

}

.banner-text-mobile{
	position:absolute;
    top:25%;
    left:12%;
	/* transform: translate(-50%,-50%); */
    width:75%;
	color:white;
	font-family: 'Arima Madurai', cursive;
	font-size:1.2rem;
	letter-spacing: .15rem;;
	word-spacing: 10px;;

}
.banner-text.small{
    position:absolute;
   
}
.container{
	background-color: #6a7c68;
    position:relative;
    bottom:0;
    min-height:50vh;
    /* width:80%; */
 
    margin:0 auto;
}
.mobile-title{
    color:white;
    font-size:2rem;
    text-align:center;
    margin:15px 0;
}
.mobile-text{
    padding:0 20px;
    font-size:1.1rem;
    letter-spacing: 1px;

    text-align: justify;
}
/* .bottom-mobile{
    position:absolute;
    right:0;

    width:30vw;
    bottom:0;
} */
.bottomNav-small {
    margin-top:15px;
	position: relative;
	/* bottom: 0%; */
    width:100%;
}
.bottomNav-small div{
	width:33.33%;
}
.link-wrap-mobile,.bottomNav-small{
    padding:0 20px;
}
.bottomNav-small a {
	text-align: left;
	flex: 1;
	margin: 0 10px;
	text-decoration: none;
	color: white;

	border-top: 3px solid rgb(99, 92, 92);
}

.bottomNav-small .active{
	border-top: 3px solid rgb(231, 213, 221);

}
.bottom-mobile{
    position: absolute;
	bottom: 0%;
	width: 30%;
	right: 10%;
}
@media(max-height:300px){
    .bottom-mobile{
        display: none;
    }
    .bgSection{
        height:210%;
    }
}